/*
* * * * * ==============================
* * * * * ==============================
* * * * * ==============================
* * * * * ==============================
========================================
========================================
========================================
----------------------------------------
USWDS THEME CUSTOM STYLES
----------------------------------------
Custom project SASS goes here.

i.e.
@include u-padding-right('05');
----------------------------------------
To use a USWDS mixin with a USWDS design token,
do this:  @include <mixin name>('<design-token>')
See the radio-option below as an example.
*/

@use 'uswds-core' as *;

.cf-alert {
  margin: 0 auto;
}

.usa-alert .usa-alert__heading {
  font-weight: font-weight('bold');
}

// -- start
// Backwards compatible step for migrating tailwind to keep some SVGs correctly
// sized. This is shared in admin and applicant sides so until we rewrite SVG
// support or migrate the admin we'll use the same settings from tailwind.
svg {
  &.w-auto {
    width: auto;
  }
  &.h-5 {
    height: 1.25rem;
  }
}
// -- end

/* Rather than having a focus outline around the checkbox or radio button,
it is around the label */
.usa-checkbox__input:focus + [class*='__label']::before,
.usa-radio__input:focus + [class*='__label']::before {
  outline: 2px solid rgba(0, 0, 0, 0);
  outline-offset: 2px;
}

.cf-radio-option:focus-within,
.usa-checkbox__input:focus + [class*='__label'],
.usa-radio__input:focus + [class*='__label'] {
  @include u-border('blue-40v');
  @include u-border('05');
}

/*
 * Make the file input element for program image upload fill the area it's given.
 * (This overrides the default USWDS style of a max-width of 30rem.)
 */
#program-image-upload-file-input .usa-file-input {
  max-width: none;
}

.cf-question-error-message {
  @include u-text('secondary-dark', 'bold');
  @include u-font('sans', 'xs');
  @include u-padding-top('05');
}

.cf-question-field-with-error {
  @include u-border-left('05', 'secondary-dark');
}

/*
 * Add padding to the left of the group question when there is an error
 * to make room for the error border.
 */
[class^='cf-question'].cf-question-field-with-error {
  @include u-padding-left('105');
}

/*
 * Less padding when there is a field-level error, since we add a border and
 * still want the inputs to line up.
 */
.cf-applicant-question-field.cf-question-field-with-error {
  @include u-padding-left('105');
}

/**
 * Reduce default padding on labels within question to match spacing mocks.
 */
.cf-applicant-question-field .usa-label {
  @include u-margin-top('105');
}

/**
 * Remove top margin because other surrounding elements add this margin.
 */
.usa-legend,
.cf-question-date .usa-memorable-date .usa-form-group {
  margin-top: 0;
}

.cf-no-margin-padding {
  margin: 0px;
  padding: 0px;
}

.cf-north-star-enumerator-field {
  @include u-padding-y('105');
}

.cf-north-star-enumerator-field:last-child {
  @include u-padding-bottom(0);
}

.page-spacing {
  @include u-height(4);
}

.content {
  @include u-margin-y('105');
}

// When a section follows breadcrumbs it'll add a larger top margin than what
// we want
.usa-breadcrumb + section {
  @include u-margin-top('105');
}

// Remove top margin from h2 headings in .usa-prose on the home page because we
// usually get enough spacing from the margin below section cards and add spacing below.
.usa-prose > h2.cf-below-cards {
  @include u-margin-top(0);
  @include u-margin-bottom(3);
}

// Remove top margin from section elements in .usa-prose on the home page
// because we sometimes get enough spacing from the margin below section cards.
.usa-prose section.cf-below-cards {
  @include u-margin-top(0);
}

.content-spacing {
  @include u-height('105');
}

// Between sections
.section-external {
  @include u-margin-top(4);
}

// Between elements within a section
.section-internal {
  @include u-margin-top(3);
}

.section {
  @include u-margin-y(3);
}

.section-spacing {
  @include u-height(3);
}

.section-bottom {
  @include u-margin-bottom(3);
}

.double-section-bottom {
  @include u-margin-bottom(6);
}

// For the applicant home page container. The bottom spacing is provided by the cards.
.padding-section-large-three-sides {
  @include u-padding-top(4);
  @include u-padding-x(4);
}

.container {
  display: flex;
  justify-content: space-between;
}

.text-medium-spacing {
  @include u-height(1);
}

.text-medium-margin-top {
  @include u-margin-top(1);
}

.zero-margin {
  margin: 0;
}

.cf-zero-top-margin {
  margin-top: 0;
}

.usa-form .usa-button.zero-top-margin {
  margin-top: 0;
}

// This should only be used to override styling.
// Prefer using class="usa-prose" in parent element where possible.
.cf-prose-h3 {
  @include u-font_size('heading', 'lg');
  @include u-line-height('sans', $theme-heading-line-height);
  font-family: var(--heading, Merriweather);
  font-weight: font-weight('bold');
}

// This should only be used to override styling.
// Prefer using class="usa-prose" in parent element where possible.
.cf-prose-h4 {
  @include u-font('sans', 'sm');
  font-weight: font-weight('bold');
  @include u-line-height('sans', $theme-heading-line-height);
}

.block-summary {
  @include u-margin-top(4);
  @include u-padding-y(2);
}

.block-summary-header {
  align-items: flex-start; // Align items to the top
  display: flex;
  justify-content: space-between;
}

.summary-edit-button {
  margin-right: 0;
  margin-top: 0;
}

// Resolve conflict between zero-margin and usa-prose>ol by directly specifying ID
#summary-multiline-list {
  margin: 0;
  text-align: right;
  padding-left: 0;
}

.summary-answer {
  @include u-line-height('sans', 5);
  @include u-text('ink');
  font-weight: font-weight('bold');
  text-align: right;
  text-overflow: ellipsis;
  word-break: break-word;
}

.summary-download-file-link {
  text-decoration-line: underline;
}

.summary-button-section {
  @include u-margin-top(3);
  margin-bottom: 0;
}

// Styles for desktop
@media (min-width: 64em) {
  // The USWDS header doesn't give enough space for the logo and title, so we add some.
  .usa-header--basic .usa-navbar {
    width: 50%;
  }

  .usa-header--basic .usa-nav {
    @include u-padding-top(4);
    @include u-padding-bottom(2);
  }
}

// Styles common to tablet and mobile
@media (max-width: 63.9em) {
  // Hide the logo on mobile and tablet
  .cf-header-logo {
    display: none;
  }

  .usa-nav .usa-button,
  .usa-nav span {
    margin-top: 8px;
  }

  .usa-nav .grid-row * {
    width: 100%;
  }
}

// Styles for mobile
@media (max-width: 47.9em) {
  // Use a single column on mobile
  .grid-container {
    grid-template-columns: 1fr;
  }

  .mobile-truncate-3 {
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    line-clamp: 3;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.usa-header--basic .usa-button {
  @include u-line-height('sans', 2);
}

/**
 * Default form styles add a bottom margin to forms, but we don't want that for the language
 * selector as it would mess with header styles.
 */

.usa-header--basic form {
  @include u-margin-bottom(0);
}

/**
 * We use h1 instead of h4 as a progress bar heading for a11y reasons, but we want to keep the
 * USWDS styles, so we ignore the default line height of h1 elements.
 */
h1.usa-step-indicator__heading {
  line-height: inherit;
}

.usa-footer__primary-content .usa-footer__primary-link {
  text-decoration: underline;
}

.expanding-div {
  flex-grow: 1;
}

.cf-block-form {
  width: 100%;
  max-width: 100%;
}

.cf-block-form .cf-input-large {
  max-width: 380px;
}

.cf-applicant-question-help-text {
  max-width: 480px;
}

// Custom gap for use on flexbox since there isn't one currently defined in default USWDS utility classes.
.cf-flex-gap {
  gap: units(1);
}

// Let the body expand to fill the entire viewport
body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

// Allow main content to grow and push footer down
main {
  flex: 1 0 auto;
}

// Mobile
.cf-program-card-group .usa-card {
  @include grid-col(12);
}

// Tablet: Program card grid has 2 columns
@media (min-width: 40em) {
  .cf-program-card-group .usa-card {
    @include grid-col(6);
  }
}

// Desktop: Program card grid has 3 columns
@media (min-width: 64em) {
  .cf-program-card-group .usa-card {
    @include grid-col(4);
  }
}

.cf-application-card {
  display: grid;
}

.cf-card-footer {
  display: flex;
  flex-wrap: wrap;
  row-gap: 0.5rem;
}

.flex-parent {
  display: flex;
}

.flex-fill {
  flex-grow: 1;
}

.success-state {
  border-radius: 2px;
  background: var(--State-tokens-success-success-lighter, #ecf3ec);
  @include u-padding-y(0.5);
  @include u-padding-x(1);
  align-items: center;
  gap: units(1);
}

.cf-eligible-tag {
  @include u-font('sans', 'xs');
}

.auto-width {
  width: auto;
}

.ti-banner-internal {
  display: inline;
}

.cf-program-card-tag {
  @include u-padding-x(1);
  @include u-padding-y(0.5);
  @include u-radius('sm');
}

.tag-in-progress {
  @include u-bg('warning-lighter');
}

.tag-submitted {
  background: var(--State-tokens-info-info-light, #99deea);
}

.cf-wrap-anywhere {
  overflow-wrap: anywhere;
}
